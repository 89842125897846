import { Injectable } from '@angular/core';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { environment } from '@nowffc-environment/environment';
import { EnvironmentName } from '@nowffc-shared/interfaces/environment';
import { TrackPaymentSuccess } from '@nowffc-shared/interfaces/track-payment-success';
import { WindowRef } from '@nowffc-shared/services/window/window';
import {
  BufferType,
  GtmConsumer,
  PublishToKafkaConsumer,
  TrackingProcessor,
} from '@plus/unified-tracking-sdk';
import { delayWhen, filter, ReplaySubject, switchMap, take } from 'rxjs';

import { Store } from '@ngrx/store';
import { ErrorsService } from '@nowffc-errors/services/errors.service';
import { UnleashService } from '@nowffc-shared/services/feature-toggle/unleash.service';
import { isGoogleTagManagerLoadingFailed } from '@nowffc-shared/services/tracking/bugsnag-blocklist';
import { ConsentService } from '@nowffc-shared/services/tracking/consent.service';
import { extractSecondLevelAndTopLevelDomain } from '@nowffc-shared/services/tracking/domain-extractor.util';
import * as fromStore from '@nowffc-state/store';
import {
  Account,
  App,
  Chatbot,
  Page,
  SubscriptionCompletedPayload,
  Teaser,
} from '@plus/tracking-model-web';
import { CookieService } from 'ngx-cookie-service';
import { LoyjoyTrackingWithSend } from '@plus/ngx-loyjoy';

/**
 * TrackingService
 */
@Injectable({
  providedIn: 'root',
})
export class TrackingService implements LoyjoyTrackingWithSend {
  /**
   * stores latest navigation event so that it can be retrieved once tracking is available
   */
  navigationEvents$ = new ReplaySubject<NavigationEnd>(1);

  accountPersonalizationId = '';

  unifiedTrackingProcessor: TrackingProcessor;

  /**
   * Wire DI
   */
  constructor(
    private readonly router: Router,
    private readonly windowRef: WindowRef,
    private readonly store: Store,
    private readonly errorService: ErrorsService,
    private readonly unleash: UnleashService,
    private readonly cookieService: CookieService,
    private readonly consentService: ConsentService,
  ) {}

  async reloadTrackingProcessor() {
    await this.consentService.refreshService();
    this.unifiedTrackingProcessor = this.initTrackingProcessor(
      Page.TypeEnum.Customerdiscoverypagetypelogin,
    );
    const gtmSettings = this.gtmSettings(
      this.windowRef.nativeWindow.location.hostname,
    );
    this.registerUnifiedTracking(gtmSettings);
  }

  /**
   * initialize tracking
   */
  // istanbul ignore next
  init() {
    if (this.isBot()) {
      return;
    }
    this.store
      .select(fromStore.auth.selectAuthenticationInitialized)
      .pipe(
        filter((initialized) => initialized),
        take(1),
        switchMap(() => {
          return this.store.select(
            fromStore.auth.selectAccountPersonalizationId,
          );
        }),
      )
      .subscribe({
        next: async (accountPersonalizationId) => {
          this.accountPersonalizationId = accountPersonalizationId || '';

          this.unifiedTrackingProcessor = this.initTrackingProcessor(
            Page.TypeEnum.Customerdiscoverypagetypelogin,
          );

          const COOKIE_CLIENT_ID = 'rtlplus_client_id';
          this.consentService
            .getConsentPromise()
            .then(() => {
              const clientId = this.cookieService.get(COOKIE_CLIENT_ID);

              this.unleash.setClientId(clientId);
            })
            .catch(() => {
              this.unleash.setClientId('');
            });

          this.observeNavigationEndEvents();
          const gtmSettings = this.gtmSettings(
            this.windowRef.nativeWindow.location.hostname,
          );
          this.registerUnifiedTracking(gtmSettings);
        },
      });
  }

  private static getAppEnvironment(
    envName: EnvironmentName,
  ): App.EnvironmentEnum {
    switch (envName) {
      case 'prod':
        return App.EnvironmentEnum.Prod;
      case 'preprod':
        return App.EnvironmentEnum.Preprod;
      default:
        return App.EnvironmentEnum.Dev;
    }
  }

  private registerUnifiedTracking(gtmSettings: {
    containerId: string;
    environment: string;
    authentication: string;
  }) {
    try {
      this.unifiedTrackingProcessor.registerConsumer(
        new PublishToKafkaConsumer({
          endpoint: environment.tracking.trackingEndpoint,
          bufferType: BufferType.INDEXED_DB,
        }),
      );
      const gtmConsumer = new GtmConsumer(gtmSettings);
      this.unifiedTrackingProcessor.registerConsumer(gtmConsumer);
      const gtmInjector = gtmConsumer.getScriptInjector();
      void gtmInjector();
    } catch (e: any) {
      if (e instanceof Error && isGoogleTagManagerLoadingFailed(e)) {
        this.errorService.logConsole(e);
      } else {
        this.errorService.logBugsnag(e);
      }
    }
    // we might want to add some kind of throttling here
    this.router.events
      .pipe(
        delayWhen(() => this.consentService.getConsentReady$()),
        filter((event): event is ActivationEnd => {
          return event instanceof ActivationEnd && !!event.snapshot.data?.rri;
        }),
      )
      .subscribe((event) => {
        const rri: Page.TypeEnum = event.snapshot.data.rri;
        this.sendPageViewed({ type: rri } as Page);
      });
  }

  private initTrackingProcessor(rri: Page.TypeEnum): TrackingProcessor {
    const app = {
      platform: App.PlatformEnum.Web,
      environment: TrackingService.getAppEnvironment(environment.name),
      name: App.NameEnum.RtLplusFfc,
      version: this.windowRef.nativeWindow.ffcAppVersion || '0.0.0',
    } as App;
    const page = {
      type: rri,
    } as Page;
    const accountPromise = Promise.resolve({
      id: this.accountPersonalizationId || '',
    } as Account);
    const consentPromise = this.consentService.getConsentPromise();
    const domain = location.hostname;
    const featureTogglesPromise = this.unleash.getAllExperiments();

    return new TrackingProcessor({
      app,
      page,
      accountPromise,
      consentPromise,
      domain,
      featureTogglesPromise,
    });
  }

  sendPageViewed(page: Page): void {
    this.unifiedTrackingProcessor.sendPageViewed(page);
  }

  sendTeaserViewed(payload: Teaser): void {
    this.unifiedTrackingProcessor.sendTeaserViewed(payload);
  }

  sendChatbotInteracted(payload: Chatbot): void {
    this.unifiedTrackingProcessor.sendChatbotInteracted(payload);
  }

  /**
   * Track the payment success
   */
  trackPaymentSuccess(data: TrackPaymentSuccess) {
    try {
      if (this.isBot()) {
        return;
      }

      this.unifiedTrackingProcessor.sendSubscriptionCompleted({
        product_id: data.product.id,
        order_id: data.order.id,
      } as SubscriptionCompletedPayload);
    } catch (e) {
      this.errorService.logBugsnag(e);
    }
  }

  /**
   * trackAccountCreatedEvent
   */
  trackAccountCreatedEvent(): void {
    try {
      if (this.isBot()) {
        return;
      }
      this.unifiedTrackingProcessor.sendAccountCreated({
        id: this.accountPersonalizationId || '',
      } as Account);
    } catch (e) {
      this.errorService.logBugsnag(e);
    }
  }

  /**
   * trackAccountLoggedInEvent
   */
  trackAccountLoggedInEvent(): void {
    try {
      if (this.isBot()) {
        return;
      }
      this.unifiedTrackingProcessor.sendAccountLoggedIn({
        id: this.accountPersonalizationId || '',
      } as Account);
    } catch (e) {
      this.errorService.logBugsnag(e);
    }
  }

  /**
   * trackAccountLoggedOutEvent
   */
  trackAccountLoggedOutEvent(): void {
    try {
      if (this.isBot()) {
        return;
      }
      this.unifiedTrackingProcessor.sendAccountLoggedOut({
        id: this.accountPersonalizationId || '',
      } as Account);
    } catch (e) {
      this.errorService.logBugsnag(e);
    }
  }

  /**
   * trackAccountVerifiedEvent
   */
  trackAccountVerifiedEvent(): void {
    try {
      if (this.isBot()) {
        return;
      }
      this.unifiedTrackingProcessor.sendAccountVerified({
        id: this.accountPersonalizationId || '',
      } as Account);
    } catch (e) {
      this.errorService.logBugsnag(e);
    }
  }

  /**
   * trackAccountDeletedEvent
   */
  trackAccountDeletedEvent(): void {
    try {
      if (this.isBot()) {
        return;
      }
      this.unifiedTrackingProcessor.sendAccountDeleted({
        id: this.accountPersonalizationId || '',
      } as Account);
    } catch (e) {
      this.errorService.logBugsnag(e);
    }
  }

  /**
   * UsageDataResetEvent
   */
  trackUsageDataResetEvent(): void {
    try {
      if (this.isBot()) {
        return;
      }
      this.unifiedTrackingProcessor.sendAccountUsageDataReset({
        id: this.accountPersonalizationId || '',
      } as Account);
    } catch (e) {
      this.errorService.logBugsnag(e);
    }
  }

  /**
   * trackSubscriptionCanceledEvent
   */
  trackSubscriptionCanceledEvent(): void {
    try {
      if (this.isBot()) {
        return;
      }
      this.unifiedTrackingProcessor.sendSubscriptionCancelled();
    } catch (e) {
      this.errorService.logBugsnag(e);
    }
  }

  /**
   * trackCancellationRevokedEvent
   */
  trackCancellationRevokedEvent(): void {
    try {
      if (this.isBot()) {
        return;
      }
      this.unifiedTrackingProcessor.sendSubscriptionCancellationRevoked();
    } catch (e) {
      this.errorService.logBugsnag(e);
    }
  }

  /**
   * listen to NavigationEnd events and handle them
   */
  // istanbul ignore next
  private observeNavigationEndEvents() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.navigationEvents$.next(event);
      }
    });
  }

  /**
   * identify bots to disable tracking and consent layer for bots
   */
  private isBot() {
    return !this.windowRef.nativeWindow.navigator.cookieEnabled;
  }

  private gtmSettings(hostname: string) {
    const domain = extractSecondLevelAndTopLevelDomain(hostname);
    return environment.googleTagManager[domain];
  }
}
