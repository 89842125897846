import { Injectable } from '@angular/core';
import {
  SourcePointConfig,
  SourcePointConsentService,
} from '@plus/sourcepoint-consent-manager';
import { firstValueFrom, from, Observable, of } from 'rxjs';
import { ErrorsService } from '@nowffc-errors/services/errors.service';
import { Consent } from '@plus/tracking-model-web';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { WindowRef } from '@nowffc-shared/services/window/window';
import { SubscriberDataSignalsState } from '../../../../signals/states/subscriber-data.signals-state';
import { AuthService } from '@nowffc-auth/auth.service';
import * as fromStore from '@nowffc-state/store';
import { Store } from '@ngrx/store';

const SOURCEPOINT_CONFIG: SourcePointConfig = {
  accountId: 1239,
  privacyManagerId: 666719,
  baseEndpoint: 'https://sourcepoint.rtl.de',
  propertyHref: 'https://plus.rtl.de',
  joinHref: true,
};

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  sourcePointConsentService?: SourcePointConsentService;
  private serviceReady?: Promise<SourcePointConsentService>;
  private readonly promiseBased = true;

  private async getConsentService(): Promise<SourcePointConsentService> {
    if (!this.serviceReady) {
      this.serviceReady = firstValueFrom(this.hasPurAbo()).then((hasPurAbo) => {
        this.sourcePointConsentService = new SourcePointConsentService({
          ...SOURCEPOINT_CONFIG,
          targetingParams: {
            hasPurAbo: hasPurAbo,
          },
          onError: (error: Error) => {
            this.errorService.logBugsnag(error);
          },
          onLogin: () => {
            this.authService.goToLogin();
          },
        });
        return this.sourcePointConsentService;
      });
    }
    return this.serviceReady;
  }

  private hasPurAbo(): Observable<boolean> {
    return this.store
      .select(fromStore.auth.selectAuthenticationInitialized)
      .pipe(
        filter((initialized) => initialized),
        take(1),
        switchMap(() =>
          this.authService.isAuthenticated().pipe(
            take(1),
            switchMap((isLoggedIn) => {
              if (isLoggedIn) {
                return from(
                  this.subscriberDataSignalsState
                    .getSubscriberData()
                    .then((subscriberDataSignal) => {
                      return !!subscriberDataSignal().purSubscription;
                    }),
                );
              } else {
                return of(false);
              }
            }),
          ),
        ),
      );
  }

  constructor(
    private readonly errorService: ErrorsService,
    private readonly store: Store,
    private readonly authService: AuthService,
    private readonly subscriberDataSignalsState: SubscriberDataSignalsState,
    private readonly windowRef: WindowRef,
  ) {
    if (!this.promiseBased) {
      this.hasPurAbo()
        .pipe(
          tap((hasPurAbo) => {
            this.sourcePointConsentService = new SourcePointConsentService({
              ...SOURCEPOINT_CONFIG,
              targetingParams: {
                hasPurAbo: hasPurAbo,
              },
              onError: (error: Error) => {
                this.errorService.logBugsnag(error);
              },
            });
          }),
        )
        .subscribe();
    } else {
      this.getConsentService();
    }
  }

  getConsentPromise(): Promise<Consent> {
    if (this.promiseBased) {
      return this.getConsentService().then((service) => {
        return service.getConsentPromise();
      });
    } else {
      if (this.sourcePointConsentService) {
        return this.sourcePointConsentService.getConsentPromise();
      } else {
        return Promise.reject();
      }
    }
  }

  getConsentedAll$(): Observable<boolean> {
    if (this.promiseBased) {
      return from(this.getConsentService()).pipe(
        switchMap((service) => {
          return service.getCurrentConsent$().pipe(
            filter(
              (consent: Consent | Error | undefined) => consent !== undefined,
            ),
            map(
              (consent) =>
                !(consent instanceof Error) && (consent?.consentedAll ?? false),
            ),
          );
        }),
      );
    } else {
      if (this.sourcePointConsentService) {
        return this.sourcePointConsentService?.getCurrentConsent$().pipe(
          map((consent: Error | Consent | undefined) => {
            return (
              !(consent instanceof Error) && (consent?.consentedAll ?? false)
            );
          }),
        );
      } else {
        return of(false);
      }
    }
  }

  getConsentReady$(): Observable<boolean> {
    if (this.promiseBased) {
      return from(this.getConsentService()).pipe(
        switchMap((service) => {
          return service.getConsentReady$();
        }),
      );
    } else {
      if (this.sourcePointConsentService) {
        return this.sourcePointConsentService?.getConsentReady$();
      } else {
        return of(false);
      }
    }
  }

  showPrivacyCenter(): void {
    this.sourcePointConsentService?.showPrivacyCenter();
  }

  private destroyMessages(): void {
    try {
      this.windowRef.nativeWindow._sp_.destroyMessages();
    } catch {
      this.errorService.logBugsnag('sourcepoint object not found in DOM', {
        severity: 'info',
      });
    }
  }

  async refreshService(): Promise<void> {
    this.destroyMessages();
    this.sourcePointConsentService = undefined;
    this.serviceReady = undefined;
    await this.getConsentService();
  }
}
