import { Address } from '@nowffc-shared/interfaces/adress';
import { AddressError } from '@nowffc-shared/types/address-error';
import { environment } from '@nowffc-environment/environment';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Signal } from '@angular/core';
import { CustomerDetailsError } from '@nowffc-shared/types/customer-details-error';
import { CustomerDetails } from '@nowffc-shared/interfaces/customer-details';
import { Store } from '@ngrx/store';
import * as fromStore from '@nowffc-state/store';
import { PaymentCreditcardComponent } from '../components/creditcard/payment-creditcard.component';
import { BillwerkService } from '@nowffc-shared/services/subscription/billwerk.service';
import { PaymentBearer } from '@nowffc-shared/types/payment-bearer';
import { ProcessPaymentDataResponse } from '@nowffc-shared/interfaces/process-payment-data-response';
import { OrderPreview } from '@nowffc-shared/interfaces/order-preview';
import { ProductInfo } from '@nowffc-shared/interfaces/product-info';
import { KameleoonService } from '@cbc/ngx-kameleoon';
import { AddressForm } from '@nowffc-shared/interfaces/forms/address-form';
import { DebitCardForm } from '@nowffc-shared/interfaces/forms/debit-card-form';
import { FormGroup } from '@angular/forms';
import { FormReadinessState } from '@nowffc-shared/interfaces/form-readiness-state';
import { PasswordForm } from '@nowffc-shared/interfaces/forms/password-form';
import { WindowRef } from '@nowffc-shared/services/window/window';
import { firstValueFrom } from 'rxjs';
import { SubscriberData } from '@nowffc-shared/interfaces/subscriber-data/subscriber-data';
import { RivertyDebitCardForm } from '@nowffc-shared/interfaces/forms/riverty-debit-card-form';
import { SessionStorageService } from '@nowffc-shared/services/storage/session-storage.service';
import { Product } from '@nowffc-shared/interfaces/product';
import { Order } from '@nowffc-shared/interfaces/order';
import { SubscriptionService } from '@nowffc-shared/services/subscription/subscription.service';

export const STORAGE_KEY_ORDER_PREVIEW = 'ffc-order-preview';

export const STORAGE_KEY_ORDER = 'ffc-order';

export const STORAGE_KEY_ORDER_PARAMS = 'ffc-order-params';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  userId: Promise<number | undefined>;

  callbackProcessPaymentDataSuccess =
    new EventEmitter<ProcessPaymentDataResponse>();

  callbackProcessPaymentDataFailure = new EventEmitter<any>();

  static conversionGoalId = 293188;
  static initialConversionGoalId = 313665;
  static initialPremiumConversionGoalId = 297915;
  static initialMaxConversionGoalId = 297914;

  formValidationFailureThrown = false;

  constructor(
    private readonly store: Store,
    private readonly billwerkService: BillwerkService,
    private readonly kameleoonService: KameleoonService,
    private readonly http: HttpClient,
    private readonly windowRef: WindowRef,
    private readonly storageService: SessionStorageService,
    private readonly subscriptionService: SubscriptionService,
  ) {
    this.userId = firstValueFrom(
      this.store.select(fromStore.auth.selectUserId),
    );
  }

  async setCustomerAddress(address: Address): Promise<
    | {
        success: true;
        value: Address;
      }
    | {
        success: false;
        error: AddressError;
      }
  > {
    return this.http
      .put<Address>(`${environment.bffUrl}/customerAddress`, address)
      .toPromise()
      .then(
        (res) => {
          return {
            success: true,
            value: res!,
          };
        },
        () => {
          return {
            success: false,
            error: 'update.address.error',
          };
        },
      );
  }

  async getCustomerDetails(): Promise<
    | {
        success: true;
        value: CustomerDetails;
      }
    | {
        success: false;
        error: CustomerDetailsError;
      }
  > {
    return this.http
      .get<CustomerDetails>(`${environment.bffUrl}/customerDetails`)
      .toPromise()
      .then(
        (res) => {
          return {
            success: true,
            value: res!,
          };
        },
        () => {
          return {
            success: false,
            error: 'retrieve.customer.details.error',
          };
        },
      );
  }

  /**
   * Returns PSP redirectUrl during booking
   * */
  async getBookingReturnUrl(): Promise<string> {
    return this.determinePSPReturnUrl(
      environment.billwerk.bookingProviderReturnUrl,
    );
  }

  /**
   * Returns PSP redirectUrl during initial booking
   * */
  async getInitialBookingReturnUrl(): Promise<string> {
    return this.determinePSPReturnUrl(
      environment.billwerk.initialBookingProviderReturnUrl,
    );
  }

  /**
   * Returns PSP redirectUrl during initial booking
   * */
  async getPurBookingReturnUrl(): Promise<string> {
    return this.determinePSPReturnUrl(
      environment.billwerk.purBookingProviderReturnUrl,
    );
  }

  /**
   * Returns PSP redirectUrl for payment method changes
   * */
  async getPaymentChangeReturnUrl(): Promise<string> {
    return this.determinePSPReturnUrl(
      environment.billwerk.changeProviderReturnUrl,
    );
  }

  /**
   * Returns an orderUUID based on the modulo of the highest 6-digit prime number and used
   * as a simple hash to disguise a userId in request communication
   * */
  generateOrderUUID(userId: number): string {
    return (userId % 999983).toString();
  }

  /**
   * Read from embedded iframe and process (allowed masked or pseudonymized)
   * credit card data for backend call
   */
  processCreditCardData(
    creditCardComp: PaymentCreditcardComponent,
    paymentBearer: PaymentBearer,
  ): void {
    const paymentInfo = creditCardComp.iFrameFormCC;
    creditCardComp.iFrameFormCC.processPaymentData(
      this.billwerkService.getOrder().GrossTotal,
      this.billwerkService.getOrder().Currency,
      async (b: any) => {
        const paymentMethodData = {
          paymentBearerType: paymentBearer,
          cardpan: b.bearer.truncatedCardPan,
        };
        this.callbackProcessPaymentDataSuccess.emit({
          orderPayment: paymentMethodData,
          paymentInfo,
        } as ProcessPaymentDataResponse);
      },
      (error: object) => {
        this.callbackProcessPaymentDataFailure.emit(error);
      },
    );
  }

  /**
   * Process bank account payment data for backend call
   */
  processBankAccountData(
    iban: string,
    bic: string,
    holder: string,
    sepaText: string,
    paymentBearer: PaymentBearer,
  ): void {
    const sanitizedIban = iban.replace(/[^\da-zA-Z]/g, '');
    const sanitizedBic = bic.replace(/[^\da-zA-Z]/g, '');

    const paymentInfo = {
      bearer: environment.billwerk.paymentMethods.debitCard,
      accountHolder: holder,
      iban: sanitizedIban,
      bic: sanitizedBic,
      mandateText: sepaText,
    };
    const paymentMethodData = {
      paymentBearerType: paymentBearer,
      iban: sanitizedIban,
      bic: sanitizedBic,
    };
    this.callbackProcessPaymentDataSuccess.emit({
      orderPayment: paymentMethodData,
      paymentInfo,
    } as ProcessPaymentDataResponse);
  }

  /**
   * Process bank account riverty payment data for backend call
   */
  processBankAccountRivertyData(
    iban: string,
    holder: string,
    dateOfBirth: Date,
    sepaText: string,
    paymentBearer: PaymentBearer,
  ): void {
    const sanitizedIban = iban.replace(/[^\da-zA-Z]/g, '');

    const paymentInfo = {
      bearer: environment.billwerk.paymentMethods.rivertyDebitCard,
      accountHolder: holder,
      birthDate: dateOfBirth,
      iban: sanitizedIban,
      mandateText: sepaText,
    };
    const paymentMethodData = {
      paymentBearerType: paymentBearer,
      iban: sanitizedIban,
    };
    this.callbackProcessPaymentDataSuccess.emit({
      orderPayment: paymentMethodData,
      paymentInfo,
    } as ProcessPaymentDataResponse);
  }

  /**
   * Process paypal and amazon pay payment data for backend call
   */
  process3rdPartyProviderData(paymentBearer: PaymentBearer): void {
    const paymentInfo = {
      bearer: paymentBearer,
    };
    const paymentMethodData = {
      paymentBearerType: paymentBearer,
    };
    this.callbackProcessPaymentDataSuccess.emit({
      orderPayment: paymentMethodData,
      paymentInfo,
    } as ProcessPaymentDataResponse);
  }

  /**
   * gather infos needed to display footnotes if applicable
   */
  getFootnotes(orderPreview: OrderPreview): string[] | undefined {
    if (!orderPreview.features?.footnotes) {
      return;
    }

    return orderPreview.features.footnotes.map(
      (footnote, index) => `${(index + 1).toString()} ${footnote.text}`,
    );
  }

  /**
   * gather info needed to present billing info to user
   */
  getProductInfo(
    orderPreview?: OrderPreview,
    subscriberData?: Signal<SubscriberData>,
  ): ProductInfo | undefined {
    if (!orderPreview || !subscriberData) {
      return;
    }
    const {
      trialEnabled,
      trialEndDate,
      product,
      scheduledChangeDate,
      upcomingProduct,
      invoiceDate,
      invoiceAmount,
      features,
      discount,
    } = orderPreview;

    const featuresText = features
      ? features.topFeatures
          .map((feature) => {
            const footnoteIndex = feature.footnoteId
              ? features.footnotes.findIndex(
                  (footnote) => footnote.id === feature.footnoteId,
                )
              : null;

            return footnoteIndex !== null
              ? feature.name + `<sup>${footnoteIndex + 1}</sup>`
              : feature.name;
          })
          .join('. ') + '.'
      : '';

    return {
      name: product.name,
      // @ts-ignore
      trialEnabled,
      trialEndDate,
      billingPeriodUnit: product.billingPeriod.unit,
      billingPeriodAmount: product.billingPeriod.quantity,
      cancellationPeriodUnit: product.cancellationPeriod.unit,
      cancellationPeriodAmount: product.cancellationPeriod.quantity,
      contractPeriodUnit: product.contractPeriod.unit,
      contractPeriodAmount: product.contractPeriod.quantity,
      invoiceDate,
      invoiceAmount,
      productPrice: product.price,
      isPackageChange: subscriberData().internalSubscription.status !== 'FREE',
      scheduledChangeDate,
      upcomingProductPrice: upcomingProduct?.price,
      upcomingProductBillingPeriodUnit: upcomingProduct?.billingPeriod?.unit,
      upcomingProductBillingPeriodAmount:
        upcomingProduct?.billingPeriod?.quantity,
      featuresText: featuresText,
      discountDetails: discount,
    };
  }

  checkBookingFormReadiness(
    withdrawalClauseAccepted: boolean,
    paymentBearer?: PaymentBearer,
    debitCardForm?: FormGroup<DebitCardForm>,
    rivertyDebitCardForm?: FormGroup<RivertyDebitCardForm>,
    creditCardComp?: PaymentCreditcardComponent,
    addressForm?: FormGroup<AddressForm>,
  ): FormReadinessState {
    // eslint-disable-next-line prefer-const
    let { isFormReady, errorMessages } = this.checkPaymentFormReadiness(
      paymentBearer,
      debitCardForm,
      rivertyDebitCardForm,
      creditCardComp,
      addressForm,
    );

    if (!withdrawalClauseAccepted) {
      errorMessages.push('payment.form.check.withdrawalclausenotaccepted');
      isFormReady &&= false;
    }

    return {
      isFormReady,
      errorMessages,
    };
  }

  checkPaymentChangeFormReadiness(
    passwordForm: FormGroup<PasswordForm>,
    paymentBearer?: PaymentBearer,
    debitCardForm?: FormGroup<DebitCardForm>,
    rivertyDebitCardForm?: FormGroup<RivertyDebitCardForm>,
    creditCardComp?: PaymentCreditcardComponent,
    addressForm?: FormGroup<AddressForm>,
  ): FormReadinessState {
    // eslint-disable-next-line prefer-const
    let { isFormReady, errorMessages } = this.checkPaymentFormReadiness(
      paymentBearer,
      debitCardForm,
      rivertyDebitCardForm,
      creditCardComp,
      addressForm,
    );

    if (!passwordForm.valid) {
      errorMessages.push('payment.form.check.password');
      isFormReady &&= false;
    }

    return {
      isFormReady,
      errorMessages,
    };
  }

  checkPaymentFormReadiness(
    paymentBearer?: PaymentBearer,
    debitCardForm?: FormGroup<DebitCardForm>,
    rivertyDebitCardForm?: FormGroup<RivertyDebitCardForm>,
    creditCardComp?: PaymentCreditcardComponent,
    addressForm?: FormGroup<AddressForm>,
  ): FormReadinessState {
    let isFormReady = true;
    const errorMessages = [];
    if (!paymentBearer) {
      errorMessages.push('payment.form.check.nopaymentbearer');
      isFormReady &&= false;
    }

    if (
      paymentBearer === 'BankAccountRiverty' &&
      !rivertyDebitCardForm?.valid
    ) {
      errorMessages.push('payment.form.check.debitformnotready');
      isFormReady &&= false;
    }

    if (paymentBearer === 'BankAccount' && !debitCardForm?.valid) {
      errorMessages.push('payment.form.check.debitformnotready');
      isFormReady &&= false;
    }

    if (paymentBearer === 'CreditCard' && !creditCardComp) {
      errorMessages.push('payment.form.check.ccformmissing');
      isFormReady &&= false;
    }

    if (
      (paymentBearer === 'BankAccount' ||
        paymentBearer === 'BankAccountRiverty' ||
        paymentBearer === 'CreditCard') &&
      !addressForm?.valid
    ) {
      errorMessages.push('payment.form.check.addressformnotready');
      isFormReady &&= false;
    }

    return {
      isFormReady,
      errorMessages,
    };
  }

  /**
   * centralize conversion tracking
   */
  processKameleoonConversion(goalId: number) {
    this.kameleoonService.processConversion(goalId);
  }

  storeOrderPreview(orderPreview: OrderPreview) {
    this.storageService.set(STORAGE_KEY_ORDER_PREVIEW, orderPreview);
  }

  retrieveOrderPreview(): OrderPreview | null {
    let orderPreview = this.storageService.get<OrderPreview>(
      STORAGE_KEY_ORDER_PREVIEW,
    );
    // TODO: remove fallback after rollout phase
    if (!orderPreview) {
      orderPreview =
        this.subscriptionService.retrieveOrderContext()?.order || null;
    }

    return orderPreview;
  }

  storeOrderParams(
    product: Product,
    offeringJwt?: string,
    promotionCode?: string,
  ) {
    this.storageService.set(STORAGE_KEY_ORDER_PARAMS, {
      product,
      offeringJwt,
      promotionCode,
    });
  }

  retrieveOrderParams(): {
    product: Product;
    offeringJwt?: string;
    promotionCode?: string;
  } | null {
    let orderParams = this.storageService.get<{
      product: Product;
      offeringJwt?: string;
      promotionCode?: string;
    }>(STORAGE_KEY_ORDER_PARAMS);
    // TODO: remove fallback after rollout phase
    if (!orderParams) {
      const orderContext = this.subscriptionService.retrieveOrderContext();
      orderParams = orderContext
        ? {
            product: orderContext.product,
            offeringJwt: orderContext.offeringJwt,
            promotionCode: orderContext.promotionCode,
          }
        : null;
    }

    return orderParams;
  }

  storeOrder(order: Order) {
    this.storageService.set(STORAGE_KEY_ORDER, order);
  }

  retrieveOrder(): Order | null {
    return this.storageService.get<Order>(STORAGE_KEY_ORDER);
  }

  clearOrder(): void {
    this.storageService.delete(STORAGE_KEY_ORDER_PARAMS);
    this.storageService.delete(STORAGE_KEY_ORDER_PREVIEW);
    this.storageService.delete(STORAGE_KEY_ORDER);
  }

  /**
   * Returns the PSP return URL with a parameterized, unique order UUID based
   * on the user's ID if available, else the standard redirection URL
   * */
  private async determinePSPReturnUrl(rawUrl: string): Promise<string> {
    const urlWithHost = rawUrl.replace(
      '$$HOST$$',
      this.windowRef.nativeWindow.document.location.host,
    );
    return this.userId.then((userId) => {
      if (userId) {
        const baseURL = new URL(urlWithHost);
        baseURL.searchParams.set('orderUUID', this.generateOrderUUID(userId));
        return baseURL.toString();
      } else {
        return urlWithHost;
      }
    });
  }
}
