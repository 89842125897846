import { Component } from '@angular/core';
import { NowOverlayData } from '../now-overlay.data';
import { NowOverlayRef } from '../now-overlay.ref';

/**
 * RTL+ Overlay Component
 */
@Component({
  selector: 'now-overlay',
  templateUrl: './now-overlay.component.html',
  styleUrls: ['./now-overlay.component.scss'],
  standalone: false,
})
export class NowOverlayComponent {
  /**
   * Headline of the Overlay
   */
  headline: string;

  /**
   * Text of the Overlay
   */
  text: string;

  /**
   * Wire DI
   */
  constructor(
    readonly data: NowOverlayData,
    readonly ref: NowOverlayRef,
  ) {
    this.headline = data.headline;
    this.text = data.text;
  }

  /**
   * closes the LoadingOverlay Reference
   */
  close() {
    this.ref.close();
  }
}
