<footer class="rtlplus-footer" role="contentinfo">
  <div class="rtlplus-container">
    <div class="top">
      <ul class="categories-container">
        @for (entry of entries; track entry) {
          <li class="categories">
            <span class="category-title">{{ entry.title }}</span>
            <ul>
              @for (link of entry.links; track $index) {
                @if (link.checkPur === false || !userHasPurSubscription) {
                  <li class="category-entry">
                    <a
                      [href]="link.href"
                      (click)="link.onClickHandler($event)"
                      target="_blank"
                      rel="noopener noreferrer"
                    >{{ link.name }}</a
                    >
                  </li>
                }
              }
            </ul>
          </li>
        }
      </ul>
      <div class="copyright">© {{ currentYear }} RTL interactive GmbH</div>
    </div>
    <div class="bottom">
      <div class="copyright">© {{ currentYear }} RTL interactive GmbH</div>
    </div>
  </div>
</footer>
