export interface ErrorConfig {
  title: string;
  subtitle: string;
  imgPath: string;
  cta?: {
    title: string;
    url: string;
  };
  link?: {
    title: string;
    url: string;
  };
}

export const DefaultError: ErrorConfig = {
  title: 'errors.unknown',
  subtitle: '',
  imgPath: '',
};

export const OFFERING_ERROR_SUBSCRIPTION_MISMATCH =
  'offering.error.subscription.mismatch';

export const OFFERING_ERROR_COUNTRY_OF_CALLER_FORBIDDEN =
  'offering.error.country.of.caller.forbidden';

export const SUBSCRIPTION_ERROR_PRODUCT_ALREADY_PURCHASED =
  'subscription.error.product.already.purchased';

export const GENERIC_ERROR = 'generic.error';

export const AppErrorConfig: Record<string, ErrorConfig> = {
  [OFFERING_ERROR_COUNTRY_OF_CALLER_FORBIDDEN]: {
    title: 'offering.error.country.of.caller.forbidden.title',
    subtitle: 'offering.error.country.of.caller.forbidden.subtitle',
    imgPath: '/assets/offering/illustrations/country-of-caller-forbidden.svg',
  },
  [OFFERING_ERROR_SUBSCRIPTION_MISMATCH]: {
    title: 'offering.error.subscription.mismatch.title',
    subtitle: 'offering.error.subscription.mismatch.subtitle',
    imgPath: '/assets/offering/illustrations/country-of-caller-forbidden.svg',
    cta: {
      title: 'offering.error.subscription.mismatch.cta.title',
      url: '/buchen',
    },
  },
  [SUBSCRIPTION_ERROR_PRODUCT_ALREADY_PURCHASED]: {
    title: 'subscription.error.product.already.purchased.title',
    subtitle: 'subscription.error.product.already.purchased.subtitle',
    imgPath: '/assets/offering/illustrations/country-of-caller-forbidden.svg',
    cta: {
      title: 'subscription.error.product.already.purchased.cta.title',
      url: '/buchen',
    },
  },
  [GENERIC_ERROR]: {
    title: 'offering.generic.error.title',
    subtitle: 'offering.generic.error.subtitle',
    imgPath: '/assets/offering/illustrations/generic-error.svg',
    cta: {
      title: 'offering.generic.error.cta.title',
      url: 'https://plus.rtl.de/musik',
    },
  },
};
