import { Injectable } from '@angular/core';
import { SubscriberDataSignalsState } from '../signals/states/subscriber-data.signals-state';
import { Router, UrlTree } from '@angular/router';
import { ErrorsService } from '@nowffc-errors/services/errors.service';

/**
 * class NoDebtCollectionGuard
 */
@Injectable({
  providedIn: 'root',
})
export class ContractRestrictedGuard {
  /**
   * Wire DI
   */
  constructor(
    private readonly subscriberDataSignalState: SubscriberDataSignalsState,
    private readonly errorsService: ErrorsService,
    private readonly router: Router,
  ) {}

  /**
   * Interface that a class can implement to be a guard deciding if a route can be activated
   */
  async canActivate(): Promise<boolean | UrlTree> {
    try {
      const subscriberData =
        await this.subscriberDataSignalState.getSubscriberData();

      if (
        subscriberData().internalSubscription.paymentEscalation
          ?.contractRestricted === true
      ) {
        this.errorsService.logToastr(
          'guard.error.debt.collection.in.progress.message',
          'guard.error.debt.collection.in.progress.headline',
          true,
        );
        return this.router.parseUrl('/account');
      }
    } catch {}

    return true;
  }

  /**
   * Interface that a class can implement to be a guard deciding if a child route can be activated
   */
  async canActivateChild(): Promise<boolean | UrlTree> {
    return this.canActivate();
  }
}
