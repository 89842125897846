import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ContentCardsOverlayService,
  NotificationBellComponent,
} from '@plus/ngx-braze-sdk';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { TranslateModule } from '@ngx-translate/core';

import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
} from '@angular/router';
import { LogoComponent } from '@nowffc-shared/components/logo/logo.component';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'now-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  imports: [
    TranslateModule,
    RouterLink,
    LogoComponent,
    NotificationBellComponent,
  ],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() isLoggedIn: boolean;
  @Input() originUrl: string;
  @Input() showMenu: boolean;
  @Input() authorizeUrl: string;

  @Output() readonly logoClicked = new EventEmitter<MouseEvent>();
  @Output() readonly toLoginClicked = new EventEmitter<MouseEvent>();

  showTransparentHeader = false;

  @ViewChild('wrapperHeader') wrapperHeader!: ElementRef;

  constructor(
    private readonly ccOverlayService: ContentCardsOverlayService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route = this.activatedRoute;

          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        switchMap((route) => route.data),
      )
      .subscribe((data) => {
        this.showTransparentHeader = data.transparentHeader;
      });
  }

  /**
   * set overlayConfig for Braze Notification Center
   */
  ngAfterViewInit(): void {
    this.ccOverlayService.overrideOverlayConfig({
      disableCloseOnOutsideClick: false,
      disableCloseFromList: false,
      size: { height: 'auto', width: 450 },
      scrollStrategy: 'block',
      flexiblePositionStrategy: {
        positionForFlexibleStrategy: {
          overlayX: 'end',
          overlayY: 'top',
          originX: 'end',
          originY: 'bottom',
        } as ConnectedPosition,
        elementForFlexibleStrategy: this.wrapperHeader,
      },
    });
  }
}
