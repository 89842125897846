import { CanActivateFn, Router } from '@angular/router';
import { UnleashService } from '@nowffc-shared/services/feature-toggle/unleash.service';
import { inject } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

export interface FeatureToggleGuardConfig {
  allEnabled?: string[];
  allDisabled?: string[];
  redirectUrl: string;
  replaceSourceUrlParts?: boolean;
}

export function featureToggleGuard(
  config: FeatureToggleGuardConfig,
): CanActivateFn {
  return (_, state) => {
    const unleashService = inject(UnleashService);
    const router = inject(Router);

    let redirect = router.parseUrl(config.redirectUrl);
    if (config.replaceSourceUrlParts ?? false) {
      redirect = router.parseUrl(
        replacePlaceholders(config.redirectUrl, state.url.split('/')),
      );
    }

    const allEnabledNames = config.allEnabled ?? [];
    const allDisabledNames = config.allDisabled ?? [];

    const allEnabled = unleashService.allEnabled(allEnabledNames);
    const allDisabled = unleashService.allDisabled(allDisabledNames);
    return combineLatest([allEnabled, allDisabled]).pipe(
      map(([enabled, disabled]) => (enabled && disabled ? true : redirect)),
    );
  };
}

const replacePlaceholders = (input: string, urlParts: string[]): string => {
  return input.replace(/<([^>]+)>/g, (_, part) => {
    return urlParts[part];
  });
};
