<div
  class="header-wrapper"
  #wrapperHeader
  [class.transparent-header]="showTransparentHeader"
  >
  <div class="header-inner">
    <div class="logo-area logo-area__wrapper">
      <a
        class="logo-area__logo"
        (click)="logoClicked.emit($event)"
        href="{{ originUrl }}"
        data-testid="main-logo"
        [attr.aria-label]="'menu.homepage' | translate"
        >
        <now-plus-logo></now-plus-logo>
      </a>
    </div>
    @if (showMenu) {
      <div class="menu-area">
        @if (isLoggedIn) {
          <ul
            class="header-menu"
            id="headerMenuLoggedIn"
            data-testid="header-logged-in"
            >
            <li class="header-menu-item header-menu-item__bell">
              <now-braze-notification-bell
                class="bell"
                [showTitle]="false"
                [openOnHover]="false"
              ></now-braze-notification-bell>
            </li>
            <li class="header-menu-item header-menu-item__account">
              <a class="nav-link" [routerLink]="'/account'">
                <span>{{ 'menu.myAccount' | translate }}</span>
              </a>
            </li>
            <li class="header-menu-item header-menu-item__homepage">
              <a class="nav-link" href="{{ originUrl }}">
                <span>{{ 'menu.homepage' | translate }}</span>
              </a>
            </li>
          </ul>
        }
        @else {
          <ul
            class="header-menu"
            id="headerMenuLoggedOut"
            data-testid="header-logged-out"
            >
            <li class="header-menu-item">
              <a class="nav-link" (click)="toLoginClicked.emit($event)">
                <span>{{ 'menu.login' | translate }}</span>
              </a>
            </li>
          </ul>
        }
      </div>
    }
  </div>
</div>
